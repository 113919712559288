import React from "react"
import APIHelper from "../../../utils/helpers/api"
import { EmptyComponent } from "../base";
import { FormRow, FormItemBuilder } from "../form";
import { Button } from "../widgets";
import MaterialSnackbar from "../material/snackbar";

/* Template - Content */

class ImageDivContent extends React.Component {
    render () {
        const {content, onchangeFunction, namePrefix} = this.props;

        return <div className="template--image-div-content">
            <p style={{margin: `2.5rem 0`}}>Updating images in the console is not yet supported.</p>
        </div>
    }
}

class PLinkContent extends React.Component {
    render () {
        const {content, onchangeFunction, namePrefix} = this.props;

        return <div className="template--plink-content">
            <FormItemBuilder label={`Text`} type={`text`} name={`${namePrefix}_plink_text`} value={content.plink.text} onChange={onchangeFunction}/>
            <FormItemBuilder label={`Link`} type={`text`} name={`${namePrefix}_plink_to`} value={content.plink.to} onChange={onchangeFunction}/>
        </div>
    }
}

class BigTextListContent extends React.Component {
    render () {
        const {content, onchangeFunction, namePrefix} = this.props;

        return <div className="template--big-text-list-content">
            <FormItemBuilder label={`List`} type={`text`} name={`${namePrefix}_list`} value={content.list.join("/")} onChange={onchangeFunction}/>
        </div>
    }
}

class ColumnDivContent extends React.Component {
    render (){
        const {content, onchangeFunction, namePrefix} = this.props;
        return <div className="template--column-div-content">
            {
                content.columns.map(({text}, idx) =>{
                    let type = "text";

                    if(text.className === "stat-description"){
                        type = "textarea";
                    }

                    return <div className={`template--column-div-content-item${text.highlighted ? " template--column-div-content-item-highlighted" : ""}`}>
                        <FormItemBuilder label={`Text`} type={type} name={`${namePrefix}_columns_${idx}_text_text`} value={text.text} onChange={onchangeFunction}/>
                        {text.highlighted ? <FormItemBuilder label={`Highlighted Text`} type={type} name={`${namePrefix}_columns_${idx}_text_highlighted_text`} value={text.highlighted.text} onChange={onchangeFunction}/> : <EmptyComponent/>}
                    </div>
                })
            }
        </div>
    }
}

class StatListContent extends React.Component {
    render (){
        const {content, onchangeFunction, namePrefix} = this.props;
        return <div className="template--stat-list-content">
            {
                content.list.map(({number, emphasizedDesc, normalDescription}, idx) =>{
                    return <div className="template--stat-list-content-item">
                         <FormItemBuilder label={`Number`} type={`text`} name={`${namePrefix}_list_${idx}_number`} value={number} onChange={onchangeFunction}/>
                         <FormItemBuilder label={`Highlighted Text`} type={`text`} name={`${namePrefix}_list_${idx}_emphasizedDesc`} value={emphasizedDesc} onChange={onchangeFunction}/>
                         <FormItemBuilder label={`Normal Text`} type={`text`} name={`${namePrefix}_list_${idx}normalDescription`} value={normalDescription} onChange={onchangeFunction}/>
                    </div>
                })
            }
        </div>
    }
}

class SectionContentTemplate extends React.Component {
    render () {
        const {title, number} = this.props.content, {onchangeFunction, index, namePrefix} = this.props;
        return <div className="template--section-div">
            <FormItemBuilder label={`Title`} type={`text`} name={`${namePrefix}_title`} value={title} onChange={onchangeFunction}/>
            <FormItemBuilder label={`Number`} type={`text`} name={`${namePrefix}_number`} value={number} onChange={onchangeFunction}/>
        </div>
    }
}

class ContactDivContentTemplate extends React.Component {
    render (){
        const {text, highlighted} = this.props.content, {onchangeFunction, index} = this.props;

        return <div className="template--contact-div-content">
            <FormItemBuilder label={`Text`} type={`text`} name={`contents_${index}_content_text`} value={text} onChange={onchangeFunction}/>
            <FormItemBuilder label={`Highlighted`} type={`text`} name={`contents_${index}_content_highlighted`} value={highlighted} onChange={onchangeFunction}/>
        </div>
    }
}

class HeroDivTextTemplate extends React.Component {
    render () {
        const {text, highlighted} = this.props.content, {onchangeFunction, namePrefix, className="template--hero-div-content"} = this.props;

        return <div className={className}>
            <FormItemBuilder label={`Text`} type={`text`} name={`${namePrefix}_hero_text`} value={text} onChange={onchangeFunction}/>
            {highlighted ? <FormItemBuilder label={`Highlighted`} type={`text`} name={`${namePrefix}_hero_highlighted_text`} value={highlighted.text} onChange={onchangeFunction}/> : <></>}
            
        </div>
    }
}

class HeroDivContentTemplate extends React.Component {
    render () {
        const {content, index, onchangeFunction} = this.props;

        return <div>
            {
                content.map((subtitle, i) =>{
                    return <FormItemBuilder label={`Sub Title`} type={`textarea`} name={`contents_${index}_content_subtitle_${i}`} value={subtitle} onChange={onchangeFunction}/>
                })
            }
        </div>
    }
}

class HeroDivWithEnumerationContentTemplate extends React.Component {
    render () {
        const {onchangeFunction, namePrefix} = this.props, {list} = this.props.content;
        return <div className="template--enum-div-content">
            {
                list.map(({number, title, description}, i) => {
                    return <div className="template--enum-div-content-item">
                        <div className="template--enum-div-content-item-number">
                            <FormItemBuilder label={`Number`} type={`text`} name={`${namePrefix}_enumeration_list_${i}_number`} value={number} onChange={onchangeFunction}/>
                        </div>
                        <div className="template--enum-div-content-item-content">
                            <div className="template--enum-div-content-item-content-title">
                                <FormItemBuilder label={`Title - Text`} type={`text`} name={`${namePrefix}_enumeration_list_${i}_title_text`} value={title.text} onChange={onchangeFunction}/>
                                <FormItemBuilder label={`Title - Highlighted`} type={`text`} name={`${namePrefix}_enumeration_list_${i}_title_highlighted`} value={title.highlighted} onChange={onchangeFunction}/>
                            </div>
                            <div className="template--enum-div-content-item-content-desc">
                                <FormItemBuilder label={`Description`} type={`textarea`} name={`${namePrefix}_enumeration_list_${i}_description`} value={description} onChange={onchangeFunction}/>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    }
}

class HeroDivWithEnumerationTemplate extends React.Component {
    render () {
        const {hero, enumeration} = this.props.content, {onchangeFunction, namePrefix} = this.props;
        return <div className="template--hero-enum-div-content">
            <HeroDivTextTemplate content={hero} onchangeFunction={onchangeFunction} namePrefix={namePrefix}/>
            <HeroDivWithEnumerationContentTemplate content={enumeration} onchangeFunction={onchangeFunction} namePrefix={namePrefix}/>
        </div>
    }
}

class HeroDivWithDescriptionTemplate extends React.Component {
    render () {
        const {hero, description} = this.props.content, {onchangeFunction, namePrefix} = this.props;
        return <div className="template--hero-desc-div-content">
            <div className="template--hero-desc-div-content-hero">
                <FormItemBuilder label={`Text`} type={`text`} name={`${namePrefix}_hero_text`} value={hero.text} onChange={onchangeFunction}/>
            </div>
            <div className="template--hero-desc-div-content-desc">
                <FormItemBuilder label={`Description`} type={`textarea`} name={`${namePrefix}_hero_description_text`} value={description.text} onChange={onchangeFunction}/>
                <PLinkContent content={description.plinkarrow} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_description_plinkarrow`}/>
            </div>
        </div>
    }
}

class AdminFormSection extends React.Component {
    render () {
        const {name = "", children} = this.props;

        return <div className="admin-page-form--section">
            <div className="admin-page-form--section-name">{name}</div>
            <div className="admin-page-form--section-fields"> {children} </div>
        </div>
    }
}

class SectionAccordionList extends React.Component {
    render () {
        const {onchangeFunction, namePrefix} = this.props, {list} = this.props.content;
        
        return <div className="template--section-accordion-div-content">
            {
                list.map(({title, content}, index) =>{
                    return <div className="template--section-accordion-div-content-item">
                        <FormItemBuilder label={`Title`} type={`text`} name={`${namePrefix}_list_${index}_title`} value={title} onChange={onchangeFunction}/>
                        <FormItemBuilder label={`Description`} type={`textarea`} name={`${namePrefix}_list_${index}_content`} value={content} onChange={onchangeFunction}/>
                    </div>
                })
            }
        </div>
    }
}

/* Template - Divs */

class SectionDivTemplate extends React.Component {
    render () {
        const {section, contents} = this.props.content, {index, onchangeFunction} = this.props;

        return <>
            <AdminFormSection name={"Section Header"}>
                {section ? <SectionContentTemplate content={section} index={index} onchangeFunction={onchangeFunction} namePrefix={`contents_${index}_section`}/>: <EmptyComponent/>}
            </AdminFormSection>
            {
                contents.map((content, idx) =>{
                    const {type} = content, namePrefix = `contents_${index}_contents_${idx}`;
                    if(type === "stats_list"){
                        return <AdminFormSection name={`Statistics`}>
                            <StatListContent content={content.content} index={index} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else if(type === "column_div"){
                        return <AdminFormSection>
                            <ColumnDivContent content={content.content} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else if(type === "big_text_list"){
                        return <AdminFormSection>
                            <BigTextListContent content={content.content} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else if(type === "plink_arrow"){
                        return <AdminFormSection name={`Link`}>
                            <PLinkContent content={content.content} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else if(type === "div_separator"){ // catch and do nothing
                        return <EmptyComponent/>
                    }
                    else if(type === "image_div"){
                        return <AdminFormSection name={`Image`}>
                            <ImageDivContent/>
                        </AdminFormSection>
                    }
                    else if(type === "hero_with_enumeration"){
                        return <AdminFormSection name={`Content`}>
                            <HeroDivWithEnumerationTemplate content={content.content} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else if(type === "hero_with_description"){
                        return <AdminFormSection name={`Content`}>
                            <HeroDivWithDescriptionTemplate content={content.content} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else if(type === "section_accordion_list"){
                        return <AdminFormSection name={`Content`}>
                            <SectionAccordionList content={content.content} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                        </AdminFormSection>
                    }
                    else{
                        console.log("no config for", type);
                        return <EmptyComponent/>
                    }
                    
                })
            }
        </>
    }
}

class ContactDivTemplate extends React.Component {
    render () {
        const {section, content} = this.props.content, {index, onchangeFunction} = this.props;

        return <>
            <div className="admin-page-form--section">
                <div className="admin-page-form--section-name">Section Details</div>
                <div className="admin-page-form--section-fields">
                    {section ? <SectionContentTemplate content={section} index={index} onchangeFunction={onchangeFunction}/>: <EmptyComponent/>}
                </div>
            </div>
            <div className="admin-page-form--section">
                <div className="admin-page-form--section-name">Text</div>
                <div className="admin-page-form--section-fields">
                    <ContactDivContentTemplate content={content} index={index} onchangeFunction={onchangeFunction}/>
                </div>
            </div>
        </>
    }
}

class HeroDivTemplate extends React.Component{
    render () {
        const {hero, subtitle} = this.props.content.content, {onchangeFunction, index, namePrefix} = this.props;

        return <>
            <div className="admin-page-form--section">
                <div className="admin-page-form--section-name">Hero Text</div>
                <div className="admin-page-form--section-fields">
                    <HeroDivTextTemplate content={hero} index={index} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`}/>
                </div>
            </div>
            <div className="admin-page-form--section">
                <div className="admin-page-form--section-name">Sub Title</div>
                <div className="admin-page-form--section-fields">
                    <HeroDivContentTemplate content={subtitle} index={index} onchangeFunction={onchangeFunction}/>
                </div>
            </div>
        </>
    }
}

class HeroDivWithLinkTemplate extends React.Component{
    render () {
        const {hero, plinkarrow} = this.props.content.content, {onchangeFunction, index, namePrefix} = this.props;

        console.log(this.props.content)

        return <>
            <div className="admin-page-form--section">
                <div className="admin-page-form--section-name">Hero Text</div>
                <div className="admin-page-form--section-fields">
                    <HeroDivTextTemplate content={hero} index={index} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content`} className="template--hero-div-with-link-content"/>
                </div>
            </div>
            <div className="admin-page-form--section">
            <div className="admin-page-form--section-name">Link</div>
                <PLinkContent content={plinkarrow} onchangeFunction={onchangeFunction} namePrefix={`${namePrefix}_content_plinkarrow`}/>
            </div>
        </>
    }
}
class SectionAdminFormBuilder extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            disabled: false,
            submitMessage : "Submit Changes"
        }
    }

    render() {
        const {content, index, onchangeFunction, onSubmitFunction} = this.props, {disabled} = this.state;
        let SectionAdminFormBuilderComponent, title, formClass = "";
        switch(content.type) {
            case "hero_div_with_subtitle" : 
                SectionAdminFormBuilderComponent = <HeroDivTemplate content={content} index={index} onchangeFunction={onchangeFunction} namePrefix={`contents_${index}`}/>;
                title = "Hero Section"; formClass = "hero-div"
                break;
            case "hero_div_with_link" : 
                SectionAdminFormBuilderComponent = <HeroDivWithLinkTemplate content={content} index={index} onchangeFunction={onchangeFunction} namePrefix={`contents_${index}`}/>;
                title = "Hero Section"; formClass = "hero-div-with-link"
                break;
            case "section_div" : 
                SectionAdminFormBuilderComponent = <SectionDivTemplate content={content} index={index} onchangeFunction={onchangeFunction}/>; 
                title = content.section.title;
                break;
            case "contact_div" : 
                SectionAdminFormBuilderComponent = <ContactDivTemplate content={content} index={index} onchangeFunction={onchangeFunction}/>; 
                title = content.section.title;
                break;
            default: 
                console.log('no config for:', content.type)
                SectionAdminFormBuilderComponent = <></>;
                title = ""; 
                break;
        }

        return <div className="admin-page-form">
            <form className={`prosource-form prosource-form--admin-section${formClass ? " prosource-form--admin-section-" + formClass : ""}`} onSubmit={onSubmitFunction}>
                <div className="admin-page-form-header">
                    <div className="admin-page-form-header--title"> {title} </div>
                    <div className="admin-page-form-header--tools">
                        <Button buttonType={`submit`} disabled={disabled}>Submit Changes</Button>
                    </div>
                </div>
                <div className="admin-page-form-body">
                    {SectionAdminFormBuilderComponent}
                </div>
            </form>
        </div>     
    }
}

class KeysFormBuilder extends React.Component {
    constructor (props) {
        super(props);

        this.toDisplayName = this.toDisplayName.bind(this);
    }

    toDisplayName = (str) =>{
        return str.split("_").join(" ").toUpperCase();
    }

    render() {
        const {form, onchangeFunction, onSubmitFunction} = this.props;

        let FormKeys = [];

        for(var key in form){
            FormKeys.push({
                "key" : key,
                "value" : form[key]
            })
        }
        return  <div className="admin-page-form">
            <form className={`prosource-form`} onSubmit={onSubmitFunction}>
                <div className="admin-page-form-header">
                    <div className="admin-page-form-header--title"> {`Contact`} </div>
                    <div className="admin-page-form-header--tools">
                        <Button buttonType={`submit`}>Submit Changes</Button>
                    </div>
                </div>
                <div className="admin-page-form-body">
                    {
                        FormKeys.map(({key, value}) =>{
                            return <FormItemBuilder label={this.toDisplayName(key)} type={`text`} name={key} value={value} onChange={onchangeFunction}/>
                        })
                    }
                </div>
            </form>
        </div>   
    }
}

/* Main Form */
class SectionAdminForm extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loaded: false,
            form: {},
            showSnackbar: false
        };

        this.formOnSubmit = this.formOnSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    async componentDidMount(){
        const {page} = this.props;

        APIHelper.Get(`/contents/section/${page}?json=true`, (err, result) => {
            if(err){
                this.setState({ isError: true })  
            }
            else{
                this.setState({form: result.data, loaded: true});
                console.log(this.state.form);
            }
        });
    }
    
    async formOnSubmit (event) {
        event.preventDefault();
        const {page} = this.props, url = `/contents/section/${page}`;

        this.setState({
            "showSnackbar" : false
        });

        APIHelper.Put({
            "urlPath" : url,
            "reqBody" : {
                "value" : JSON.stringify(this.state.form)
            }
        }, (err, response) =>{
            if(err){
                this.setState({
                    hasError: true, error: err
                });
            }
            else{

                this.setState({
                    "showSnackbar" : true
                });
                
                setTimeout(() =>{
                    this.setState({"submitMessage" : ""})
                }, 2000);
            }
        });
    }

    onChange = event =>{
        const target = event.target, {name} = target;

        let value = target.value, keys = name.split("_"); const main_key = keys.shift(), index_key = keys.shift();

        if(main_key === "contents"){
            const contentsValue = this.state.form.contents.map((content, index) =>{
                if(parseInt(index_key) === index){

                    if(name === "contents_2_contents_0_content_list"){
                        value = value.split("/");
                    }

                    if(keys.length === 2){
                        content[keys[0]][keys[1]] = value;
                    }
                    else if(keys.length === 3){
                        content[keys[0]][keys[1]][keys[2]] = value;
                    }
                    else if(keys.length === 4){
                        content[keys[0]][keys[1]][keys[2]][keys[3]] = value;
                    }
                    else if(keys.length === 5){
                        content[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]] = value;
                    }
                    else if(keys.length === 6){
                        content[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]] = value;
                    }
                    else if(keys.length === 7){
                        content[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]][keys[6]] = value;
                    }
                    else if(keys.length === 8){
                        content[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]][keys[6]][keys[7]] = value;
                    }
                    else{
                        console.log("new:", name, keys.length)
                    }

                    return content;
                }
                else{
                    return content;
                }
            })

            let {form} = this.state;
            form.contents = contentsValue;

            this.setState({
                "form" : form
            })
        }
        else{
            this.setState({
                form: {
                    [name] : value
                }
            })
        }
    }

    render() {
        const {loaded, showSnackbar} = this.state, {title, contents} = this.state.form;
        return !loaded ? <></> : 
        <div className="admin-page-form-list">
            {
                contents? contents.map((content, index) =>{
                    return <SectionAdminFormBuilder content={content} index={index} onchangeFunction={this.onChange} onSubmitFunction={this.formOnSubmit}/>
                }) : <KeysFormBuilder form={this.state.form} onchangeFunction={this.onChange} onSubmitFunction={this.formOnSubmit}/>
            }
            {showSnackbar ? <MaterialSnackbar open={true} variant={`success`} message={`Changes saved!`}/> : <></>}
        </div>
    }
}

export default SectionAdminForm;