import React from "react"

export class Alert extends React.Component {
    render(){
        const {type = "default", message = "", style} = this.props;

        const className = `m-alert alert-${type}`;

        return <div className={className} role="alert" style={style}>
            {message}
        </div>
    }
}

export class Button extends React.Component {
    render() {
        const {buttonType = "button", type="default", children, style, disabled=null} = this.props;

        const className = `m-btn btn-${type}`

        return <button type={buttonType} class={className} style={style} disabled={disabled}>{children}</button>
    }
}